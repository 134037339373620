import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

export const DateContext = React.createContext();

export function DateContextProvider({ children, preSelectedDate }) {
  // eslint-disable-next-line
  const defaultValue = preSelectedDate
    ? preSelectedDate
    : typeof window !== 'undefined' &&
      window.localStorage.getItem('selectedDate');
  const [selectedDate, setSelectedDate] = useState(defaultValue);
  const [emptyDays, setEmptyDays] = useState([]);

  const pushEmptyDay = (day) => setEmptyDays([...emptyDays, day]);

  const popEmptyDay = (day) => {
    const _emptyDays = emptyDays.filter((item) => item !== day);
    setEmptyDays(_emptyDays);
  };

  const isEmptyDay = (day) => emptyDays.find((emptyDay) => emptyDay === day);

  const selectDate = (date) => {
    setSelectedDate(date);
  };

  let storedDate = format(new Date(), 'yyyy-MM-dd');
  if (typeof window !== 'undefined') {
    storedDate = window.localStorage.getItem('selectedDate');
  }

  useEffect(() => {
    const today = format(new Date(), 'yyyy-MM-dd');
    if (!preSelectedDate) {
      localStorage.setItem('selectedDate', today);
      setSelectedDate(today);
    }
  }, [storedDate, preSelectedDate]);

  return (
    <DateContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        selectDate,
        emptyDays,
        pushEmptyDay,
        popEmptyDay,
        isEmptyDay,
      }}
    >
      {children}
    </DateContext.Provider>
  );
}

DateContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  preSelectedDate: PropTypes.string,
};
DateContextProvider.defaultProps = {
  preSelectedDate: undefined,
};
