/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Imports themes and global styles
import React from 'react';
import './src/shared/styles/index.scss';

import { DateContextProvider } from './src/context/DateContext';

export const onServiceWorkerUpdateReady = () => {
  const answer = 'This app has been updated. display the latest version?';
  if (answer === true) {
    window.location.reload();
  }
};

export const wrapRootElement = ({ element }) => (
  <DateContextProvider>{element}</DateContextProvider>
);
